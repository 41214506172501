import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  margin: 0 0 30px;
  overflow: hidden;

  @media ${DeviceUp.sm} {
    margin: 0 0 60px;
  }
`;

export const MainHeading = styled.div`
  font-weight: 600;
  font-size: 30px;
  margin: 0 0 30px;

  @media ${DeviceUp.xl} {
    font-size: 36px;
  }

  a {
    color: ${theme.secondary};
    font-size: ${theme.lgFontSize};
    font-weight: normal;

    @media ${DeviceUp.xs} {
      display: inline-block;
      vertical-align: middle;
      margin: 4px 0 0 23px;
    }
    
    &:hover {
      color: ${theme.text};
    }
    i {
      font-size: 20px;
      padding: 0 0 0 7px;
      position: relative;
      top: 4px;
    }
  }

  span {
    display: block;
    @media ${DeviceUp.xs} {
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

export const StatsHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Block = styled.div`
  border-radius: 5px;
  border: ${theme.borderSecondary};
  height: 118px;
  margin: 5px 15px;
  padding: 18px 20px;
  overflow: hidden;
  width: 100%;

  @media ${DeviceUp.xs} {
    width: 49%;
  }

  @media ${DeviceUp.xl} {
    width: 24%;
  }
  .numbers {
    display: block;
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 5px;

    @media ${DeviceUp.xl} {
      font-size: 36px;
    }
  }
  .text-info {
    display: block;
    font-weight: 600;
    i {
      color: ${theme.MediumGray};
      position: relative;
      top: 3px;
      right: -4px;
      font-size: 16px;

      @media ${DeviceUp.xl} {
        top: 3px;
      }
    }
  }
`;
