import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp, DeviceDown } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  position: relative;

  .section-loader {
    position: absolute;
    height: auto;
  }

  .page-counter {
    margin-left: 0;
  }
`;

export const StyledCourseStats = styled.div`
  padding-top: 40px;
  margin: 0 0 40px;
`;

export const DataBlock = styled.div`
  margin: 0 0 10px;
  overflow: hidden;
  @media ${DeviceUp.xs} {
    margin: 0 0 10px 38px;
  }

  &.v2 {
    margin: 0 0 10px;
    
    @media ${DeviceUp.lg} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }


  .select-info {
    margin: 2px 0 12px;
    @media ${DeviceUp.xs} {
      float: left;
      margin: 2px 0 0;
    }
    .block {
      font-weight: 600;
      overflow: hidden;
      .info {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
      }
      .clear {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
        color: ${theme.secondary};
        cursor: pointer;
        &:hover {
          color: ${theme.text};
        }
      }
    }
  }
  .results-info {
    @media ${DeviceUp.xs} {
      float: right;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
    select {
      background: ${theme.bodyBg};
      border: 1.75px solid ${theme.gray};
      border-radius: 3.5px;
      color: ${theme.textSecondary};
      outline: none;
      padding: 4px 11px 4px 5px;
      display: inline-block;
      vertical-align: middle;
      min-width: 70px;
    }

    @media ${DeviceDown.sm} {
        display: flex ;
        justify-content: flex-end;
        align-items: center;
    }
  }
`;

export const Pagination = styled.div`
 max-width: 400px;
 margin: -12px auto 0;
 text-align: center;
 font-weight: 600;
 ul {
   li {
     display: inline-block;
     vertical-align: middle;
     margin: 0 9px;
     a {
      color: ${theme.secondary};
      &:hover {
        color: ${theme.text};
      }
     }
     &.prev,
     &.next {
       a {
        font-size: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: ${theme.secondary};
        display: block;
        color: ${theme.bodyBg};
        i {
          font-size: 16px;
          line-height: 32px;
        }
        &:hover {
          background: ${theme.text};
        }
       }
     }
   }
 }
`;

export const AnalyticsStats = styled.div`
  .view-all {
    display: none;
  }
`;
export const TableHeader = styled.div`
  h2 {
    @media ${DeviceDown.sm} {
      font-size: 24px;
      line-height: 31px;
    }
  }
`;

export const ResultsCounter = styled.div`
  margin:  0 0 40px;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 0 20px 40px;
  }

  span {
    color: ${theme.text};
  }
`;

export const InfoBlock = styled.div`
  margin: 0 0 20px;
  
  @media ${DeviceUp.sm} {
    display: flex;
    align-items: center;
  }

  @media ${DeviceUp.lg} {
    margin: 0 0 0 40px;
  }

  &.v2 {
    display: flex;
    align-items: center;
  }

  &.v3 {
    justify-content: space-between;
  }

  &.v4 {
    display: flex;
    align-items: center;
  }

  .search-block {
    flex-grow: 1;
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;

    @media ${DeviceUp.sm} {
      flex-grow: inherit;
      width: 315px;
    }
    .icon-Search-2-Outline-Color {
      font-size: 20px;
      margin: 12px 12px 12px 17px;
      position: absolute ;
      left: 0;
      top:0 ;
      z-index: 1;
    }
    input[type="search"] , input[type="text"] {
      border: 0;
      color: ${theme.primaryText};
      font-size: ${theme.lgFontSize};
      height: 46px;
      flex-grow: 1;
      padding-right: 15px;
      position: relative;
      width: 100%;
      border: 2px solid ${theme.gray};
      border-radius: 4px;
      padding: 0 37px 0 48px ;

      &:focus {
        border-color: #56B7F7;
        outline: 0;
        box-shadow: 0 0 0 3px rgba(86,183,247,.25);
      }

      &::-webkit-input-placeholder {
        color: ${theme.gray};
      }

      &:-ms-input-placeholder {
        color: ${theme.gray};
      }

      &::placeholder {
        color: ${theme.gray};
      }
    }
    .clear-search {
      position: absolute;
      right: 6px;
      top: 11px;
      width: 18px;
      cursor: pointer;

      &:hover {
        path {
          stroke: ${theme.gray};
        }
      }

      svg {
        display: block;
        width: 100%;
      }

      path {
        stroke: ${theme.primary};
      }
    }
  }
  .actions-block {
    margin: 0 0 0 16px;

    @media ${DeviceUp.sm} {
      margin: 0 0 0 28px;
    }
    button {
      background: none;
      color: ${theme.text};
      border-radius: 0;
      height: auto;
      border: 0;
      padding: 0;
      font-size: 13px;

      .icon {
        font-size: 15px;
        margin:  0 5px 0 0;
        color: ${theme.secondary};
      }
      &:hover {
        color: ${theme.secondary};
      }
      &.disabled {
        background: none;
        color: ${theme.gray};
      }

      + button {
        margin: 0 0 0 40px;
      }
    }
  }

  &.v2 {
    margin-left: 0;
    display:block ;
    @media ${DeviceUp.sm} {
      display: flex;
      align-items: center;
    }
    @media ${DeviceDown.sm} {
      .actions-block {
        margin: 15px 0 0 0;
      }
    }
  }
`;

export const SearchTagsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin:  0 -5px;
  padding-top: 10px;

  @media ${DeviceUp.sm} {
    padding-top: 20px;
  }

  .tag-button {
    margin: 0 5px 10px;
    border-radius: 18px;
    padding: 5px 18px;
    background-color: ${theme.primary};
    min-height: 36px;
    line-height: 18px;
    height: auto;
    border: 0;
    color: ${theme.bodyBg};
    display: flex;
    align-items: center;
    cursor: default;
    word-break: break-all;

    button {
      margin: 0;
      padding: 0;
      background: none;
      border: 0;
      height: auto;
      @media ${DeviceDown.sm} {
        font-size: 14px;
      }

      &:hover {
        color: #fff;

        path {
          stroke: #fff;
        }
      }
    }

    @media ${DeviceDown.sm} {
      padding:  6px 10px;
      font-size: 12px;
    }

    img {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .clear-link {
    margin-top: 8px;
    padding:  0 5px;
    color: ${theme.secondary};
    svg {
      width: 16px;
      margin: 1px 0 0 6px;
      vertical-align: top;
      height: 16px;
    }
    .icon {
      margin: 1px 0 0 6px;
      vertical-align: top;
      display: inline-block;
    }
  }
`;
