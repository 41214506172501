import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { analyticsActions } from 'analytics/actions';
import { DebounceInput } from 'react-debounce-input';
import PaginationBlock from 'common/pagination';
import AnalyticsDrawer from 'components/analyticsdrawer';
import PaginationLoader from 'components/loader/paginationLoader';
import TableResultSelection from 'common/table-results-selection';
import { ReactComponent as IconRemove } from 'assets/images/icon-remove.svg';
import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';
import {
  EMPTY_LIST_PLACEHOLDER,
  INITIAL_STATE_FETCH_LEARNERS,
  ROUTES,
  PROGRESS_FILTERS_DATA,
} from 'utils/values';
import { getUserNameForNav } from 'utils/functions';
import { TableBlock } from 'assets/styles/table-styles';
import {
  Styled,
  DataBlock,
  TableHeader,
  InfoBlock,
  SearchTagsList,
} from './styled';

const LearnerStats = (props) => {
  const [inProgress, setProgress] = useState(false);
  const { dispatch, learners, count, filters, progressFilters } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerFilters, setDrawerFilters] = useState({ ...progressFilters });
  const [searchText, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(INITIAL_STATE_FETCH_LEARNERS.filters.page_size);

  useEffect(() => {
    setProgress(true);
    const params = INITIAL_STATE_FETCH_LEARNERS.filters;
    Object.keys(progressFilters).forEach((key) => {
      progressFilters[key] = false;
    });
    setDrawerFilters({ ...progressFilters });
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  }, [dispatch, progressFilters, setDrawerFilters]);

  const handlePageChange = (property, value) => {
    if (inProgress) return;
    const params = {
      ...filters,
      [property]: value,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  };

  const copyFilters = () => {
    Object.keys(drawerFilters).forEach((key) => {
      progressFilters[key] = drawerFilters[key];
    });
  };

  const handleApplyFilters = (search, useSearchText) => {
    const filterParams = {
      progress_filters: progressFilters,
      search: useSearchText ? search : searchText,
    };
    filterParams.search = filterParams.search.trim();
    copyFilters();
    const params = {
      ...filterParams,
      page_size: pageSize,
      page: 1,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
    setShowDrawer(false);
    setDrawerFilters({ ...progressFilters });
  };

  const handlePageSize = (value) => {
    const params = {
      ...filters,
      page_size: value,
      page: 1,
    };
    setProgress(true);
    setPageSize(value);
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  };

  const resetFilterValues = () => {
    Object.keys(progressFilters).forEach((key) => {
      progressFilters[key] = false;
      drawerFilters[key] = false;
    });
  };

  const clearAllFilters = () => {
    resetFilterValues();
    handleApplyFilters();
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    handleApplyFilters(value, true);
  };

  const downloadLearnerData = () => {
    copyFilters();
    analyticsActions.downloadData(
      INITIAL_STATE_FETCH_LEARNERS.filters,
      progressFilters,
      searchText.trim(),
      'learner',
      'Learner Stats',
      setProgress,
    );
  };

  return (
    <Styled>
      <TableHeader>
        <h2>Learners</h2>
        {
          inProgress && <PaginationLoader />
        }
        <InfoBlock className="v2">
          <div className="search-block">
            <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
            <DebounceInput
              minLength={1}
              debounceTimeout={300}
              value={searchText}
              placeholder="Search Learners"
              onChange={(event) => handleSearchChange(event.target.value)}
              type="text"
            />
            {
              searchText && (
                <span className="clear-search" onClick={() => handleSearchChange('')}>
                  <IconClose />
                </span>
              )
            }
          </div>
          <div className="actions-block">
            <button onClick={() => { downloadLearnerData(); }}>
              <span className="icon-Download-Outline-Color icon" /> Download .CSV Report
            </button>
            <button onClick={() => { setShowDrawer(true); }}><span className="icon-Filter icon" /> Filters</button>
          </div>
        </InfoBlock>
        <DataBlock className="v2">
          <SearchTagsList>
            {
              (progressFilters.completed || progressFilters.in_progress)
              && (
              <span className="tag-button">
                <button type="button" onClick={clearAllFilters}>
                  Completion Status: {progressFilters.completed && PROGRESS_FILTERS_DATA.completed.label}
                  {progressFilters.completed && progressFilters.in_progress && ', '}
                  {progressFilters.in_progress && PROGRESS_FILTERS_DATA.in_progress.label}
                  <IconRemove />
                </button>
              </span>
              )
            }
          </SearchTagsList>
          <TableResultSelection
            handleOnChange={handlePageSize}
            pageSize={INITIAL_STATE_FETCH_LEARNERS.filters.page_size}
          />
        </DataBlock>
      </TableHeader>
      <TableBlock className="course-table responsive">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Last Login</th>
              <th>Assigned Courses</th>
              <th>Incomplete Courses</th>
              <th>Completed Courses</th>
            </tr>
          </thead>
          <tbody>
            {
              learners && (
                learners.map((learner) => (
                  <tr key={learner.id}>
                    <td data-title="Name">
                      { getUserNameForNav(learner, `${ROUTES.ANALYTICS}${ROUTES.LEARNER}`) }
                    </td>
                    <td data-title="Email">
                      <a target="_blank" rel="noreferrer" href={`mailto:${learner.email}`}>{learner.email}</a>
                    </td>
                    <td data-title="Last Login">
                      {
                        learner.last_login
                          ? (
                            <Moment format="hh:mm a, Do MMM YYYY">
                              {learner.last_login}
                            </Moment>
                          ) : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Assigned Courses">
                      {
                        learner.assigned_courses ? learner.assigned_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Incomplete Courses">
                      {
                        learner.incomplete_courses ? learner.incomplete_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Completed Courses">
                      {
                        learner.completed_courses ? learner.completed_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </table>
      </TableBlock>
      {
        learners.length === 0 && (
          <div className="no-data">No results to show</div>
        )
      }
      { learners
        && Object.keys(learners).length > 0
        && (
          <PaginationBlock
            activePage={filters.page}
            itemsCountPerPage={filters.page_size}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            showPageCounter
          />
        )}
      <AnalyticsDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        drawerFilters={drawerFilters}
        setDrawerFilters={setDrawerFilters}
        handleApplyFilters={handleApplyFilters}
        progressFilters={progressFilters}
        note="learnerNote"
      />
    </Styled>
  );
};

function mapStateToProps(state) {
  const { results, count, filters } = state.LEARNERSDATA;
  const progressFilters = filters.progress_filters;
  return {
    count,
    filters,
    progressFilters,
    learners: results,
  };
}

export default connect(mapStateToProps)(LearnerStats);
