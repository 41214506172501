import analyticsConstants from 'analytics/constants';
import { analyticsService } from 'services/analytics';
import { messageNotification } from 'utils/functions';
import STRINGS from 'utils/strings';

const fetchLearners = ({ params, setProgress }) => (dispatch) => {
  analyticsService
    .fetchLearners(params)
    .then((response) => {
      const { data } = response;
      Promise.resolve(
        dispatch({
          type: analyticsConstants.FETCH_LEARNERS,
          data,
          filters: params,
        }),
      );
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.defaultAjaxErrorTitle,
        STRINGS.defaultAjaxErrorMessage,
      );
      setProgress(false);
    });
};

const fetchCourseStats = ({ params, setProgress }) => (dispatch) => {
  analyticsService
    .fetchCoursesStats(params)
    .then((response) => {
      const { data } = response;
      Promise.resolve(
        dispatch({
          type: analyticsConstants.FETCH_COURSES_STATS_SUCCESS,
          data,
          filters: params,
        }),
      );
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
      );
      setProgress(false);
    });
};

const setLearnerProgressFilters = (filters) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: analyticsConstants.LEARNER_PROGRESS_FILTERS,
      progressFilters: filters,
    }),
  );
};

const setCourseProgressFilters = (filters) => (dispatch) => {
  Promise.resolve(
    dispatch({
      type: analyticsConstants.COURSE_PROGRESS_FILTERS,
      progressFilters: filters,
    }),
  );
};

const downloadData = (filters, progressFilters, searchText, mode, filename, setProgress) => {
  const params = { ...filters };
  params.progress_filters = progressFilters;
  params.search = searchText;
  params.mode = mode;
  params.offset = -(new Date().getTimezoneOffset() / 60);
  setProgress(true);
  analyticsService
    .downloadStatsCSV(params)
    .then((response) => {
      const { data } = response;
      const format = 'csv';
      const updatedFilename = `${filename}.${format}`;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: 'text/csv; charset=utf-9-sig' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        updatedFilename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
      );
      setProgress(false);
    });
};

/* eslint-disable import/prefer-default-export */
export const analyticsActions = {
  fetchLearners,
  fetchCourseStats,
  setLearnerProgressFilters,
  setCourseProgressFilters,
  downloadData,
};
