/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'chart.js';
import { LMS_BASE_URL } from 'config';

const axios = require('axios');

const fetchLoginData = async (startDate, endDate, granularity) => {
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];

  const { data } = await axios({
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/analytics/login/`,
    params: {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      granularity,
    },
  });

  return {
    labels: data.labels,
    datasets: [
      {
        label: 'Number of Learners',
        data: data.data,
        fill: false,
        backgroundColor: 'rgb(57, 91, 135)',
        borderColor: 'rgba(57, 91, 135, 0.7)',
        borderWidth: 5,
      },
    ],
  };
};

const LearnerLoginGraph = () => {
  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setDate(defaultEndDate.getDate() - 30);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [granularity, setGranularity] = useState('days');
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const data = await fetchLoginData(startDate, endDate, granularity);
      setGraphData(data);
    }
    fetchData();
  }, [startDate, endDate, granularity]);

  const handleGranularityChange = (e) => {
    setGranularity(e.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ width: '80%' }}>
        <Line data={graphData} />
      </div>

      <div style={{
        marginLeft: '30px',
        marginTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'flex-start',
      }}
      >
        <label style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <strong>Start Date:</strong>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </label>

        <label style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <strong>End Date:</strong>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            maxDate={new Date()}
          />
        </label>

        <label style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <strong>Granularity:</strong>
          <select value={granularity} onChange={handleGranularityChange}>
            <option value="days">Days</option>
            <option value="months">Months</option>
            <option value="quarters">Quarters</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export default LearnerLoginGraph;
