import { combineReducers } from 'redux';
import { userReducer } from 'users/reducers';
import { analyticReducer } from 'analytics/reducers';

const rootReducer = combineReducers({
  LEARNERSDATA: analyticReducer.fetchLearners,
  COURSESTATDATA: analyticReducer.fetchCourseStats,
  USERSDATA: userReducer.fetchUsers,
  COURSES: userReducer.fetchCourses,
  LOGGEDINUSER: userReducer.fetchLoggedInUsers,
  USER_SEARCH_OPTIONS: userReducer.fetchUserSearchOptions,
});

export default rootReducer;
