import React from 'react';
import CheckBox from 'common/checkbox';
import { PROGRESS_FILTERS_DATA } from 'utils/values';

import {
  HeadingHolder,
  ButtonsHolder,
  DrawerContainer,
} from './styled';

const AnalyticsDrawer = (props) => {
  const {
    showDrawer,
    setShowDrawer,
    progressFilters,
    drawerFilters,
    setDrawerFilters,
    handleApplyFilters,
    note,
  } = props;

  const updateAllFilters = (value) => {
    Object.keys(drawerFilters).forEach((key) => {
      drawerFilters[key] = value;
    });
    setDrawerFilters({ ...drawerFilters });
  };

  const clearAllFilters = () => {
    updateAllFilters(false);
  };

  const handleCancelFilters = () => {
    setShowDrawer(false);
    setDrawerFilters({ ...progressFilters });
  };

  const handleCompletionFilters = (event, key) => {
    const { checked } = event.target;
    drawerFilters[key] = checked;
    setDrawerFilters({ ...drawerFilters });
  };

  const selectAllCompletionFilters = (event) => {
    const { checked } = event.target;
    updateAllFilters(checked);
  };

  const ProgressList = Object.keys(drawerFilters).map((key) => (
    <li key={key}>
      <CheckBox
        dataLabel={PROGRESS_FILTERS_DATA[key].label}
        isChecked={drawerFilters[key]}
        callback={(e) => handleCompletionFilters(e, key)}
      />
      <span className="status-note">{PROGRESS_FILTERS_DATA[key][note]}</span>
    </li>
  ));

  return (
    <DrawerContainer className={`drawer-${showDrawer ? 'shown' : 'hidden'}`}>
      <div className="drawer-content">
        <div className="drawer-header">
          <HeadingHolder>
            <h3>Filters</h3>
            <button type="button" className="link" onClick={clearAllFilters}>
              Clear All
              <span className="icon-Cancel icon" />
            </button>
          </HeadingHolder>
          <ButtonsHolder>
            <button type="button" className="outline" onClick={handleCancelFilters}>
              Cancel
              <span className="icon-Cancel icon" />
            </button>
            <button type="button" onClick={handleApplyFilters}>
              Apply
              <span className="icon-Check icon" />
            </button>
          </ButtonsHolder>
        </div>
        <div className="drawer-body">
          <div className="drawer-row">
            <div className="drawer-col">
              <header>
                <CheckBox
                  dataLabel="Completion Status"
                  isChecked={drawerFilters.in_progress && drawerFilters.completed}
                  callback={(e) => selectAllCompletionFilters(e)}
                />
              </header>
              <ul className="checkbox-list">
                {ProgressList}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DrawerContainer>
  );
};

export default AnalyticsDrawer;
