import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { analyticsActions } from 'analytics/actions';
import { TableBlock } from 'assets/styles/table-styles';
import { DebounceInput } from 'react-debounce-input';
import PaginationBlock from 'common/pagination';
import PaginationLoader from 'components/loader/paginationLoader';
import TableResultSelection from 'common/table-results-selection';
import AnalyticsDrawer from 'components/analyticsdrawer';
import { ReactComponent as IconRemove } from 'assets/images/icon-remove.svg';
import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';
import {
  INITIAL_STATE_FETCH_COURSES,
  PROGRESS_FILTERS_DATA,
} from 'utils/values';
import {
  TableHeader,
  StyledCourseStats,
  DataBlock,
  InfoBlock,
  SearchTagsList,
} from './styled';

const CourseStats = (props) => {
  const { dispatch, courses, count, filters, courseProgressFilters } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerCourseFilters, setDrawerCourseFilters] = useState({ ...courseProgressFilters });
  const [inProgress, setProgress] = useState(false);
  const [searchText, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(INITIAL_STATE_FETCH_COURSES.filters.page_size);

  useEffect(() => {
    setProgress(true);
    const params = INITIAL_STATE_FETCH_COURSES.filters;
    Object.keys(courseProgressFilters).forEach((key) => {
      courseProgressFilters[key] = false;
    });
    setDrawerCourseFilters({ ...courseProgressFilters });
    dispatch(analyticsActions.fetchCourseStats({ params, setProgress }));
  }, [dispatch, courseProgressFilters, setDrawerCourseFilters]);

  const handlePageChange = (property, value) => {
    if (inProgress) return;
    const params = {
      ...filters,
      [property]: value,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchCourseStats({ params, setProgress }));
  };

  const handlePageSize = (value) => {
    const params = {
      ...filters,
      page_size: value,
      page: 1,
    };
    setProgress(true);
    setPageSize(value);
    dispatch(analyticsActions.fetchCourseStats({ params, setProgress }));
  };

  const copyFilters = () => {
    Object.keys(drawerCourseFilters).forEach((key) => {
      courseProgressFilters[key] = drawerCourseFilters[key];
    });
  };

  const handleApplyFilters = (search, useSearchText) => {
    const filterParams = {
      progress_filters: courseProgressFilters,
      search: useSearchText ? search : searchText,
    };
    filterParams.search = filterParams.search.trim();
    copyFilters();
    const params = {
      ...filterParams,
      page_size: pageSize,
      page: 1,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchCourseStats({ params, setProgress }));
    setShowDrawer(false);
    setDrawerCourseFilters({ ...courseProgressFilters });
  };

  const resetFilterValues = () => {
    Object.keys(courseProgressFilters).forEach((key) => {
      courseProgressFilters[key] = false;
      drawerCourseFilters[key] = false;
    });
  };

  const clearAllFilters = () => {
    resetFilterValues();
    handleApplyFilters();
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    handleApplyFilters(value, true);
  };

  const downloadCourseData = () => {
    copyFilters();
    analyticsActions.downloadData(
      INITIAL_STATE_FETCH_COURSES.filters,
      courseProgressFilters,
      searchText.trim(),
      'course',
      'Course Stats',
      setProgress,
    );
  };

  return (
    <StyledCourseStats>
      <TableHeader>
        <h2>Courses and Training</h2>
        {
          inProgress && <PaginationLoader />
        }
        <InfoBlock className="v2">
          <div className="search-block">
            <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
            <DebounceInput
              minLength={1}
              debounceTimeout={300}
              value={searchText}
              placeholder="Search Courses"
              onChange={(event) => handleSearchChange(event.target.value)}
              type="text"
            />
            {
              searchText && (
                <span className="clear-search" onClick={() => handleSearchChange('')}>
                  <IconClose />
                </span>
              )
            }
          </div>
          <div className="actions-block">
            <button onClick={() => { downloadCourseData(); }}>
              <span className="icon-Download-Outline-Color icon" /> Download .CSV Report
            </button>
            <button onClick={() => { setShowDrawer(true); }}><span className="icon-Filter icon" /> Filters</button>
          </div>
        </InfoBlock>
        <DataBlock className="v2">
          <SearchTagsList>
            {
              (courseProgressFilters.completed || courseProgressFilters.in_progress)
              && (
              <span className="tag-button">
                <button type="button" onClick={clearAllFilters}>
                  Completion Status: {courseProgressFilters.completed && PROGRESS_FILTERS_DATA.completed.label}
                  {courseProgressFilters.completed && courseProgressFilters.in_progress && ', '}
                  {courseProgressFilters.in_progress && PROGRESS_FILTERS_DATA.in_progress.label}
                  <IconRemove />
                </button>
              </span>
              )
            }
          </SearchTagsList>
          <TableResultSelection
            handleOnChange={handlePageSize}
            pageSize={INITIAL_STATE_FETCH_COURSES.filters.page_size}
          />
        </DataBlock>
      </TableHeader>
      <TableBlock className="course-table responsive">
        <table>
          <thead>
            <tr>
              <th>Course Title</th>
              <th>Assigned</th>
              <th>In Progress</th>
              <th>Completed</th>
              <th>Completion Rate</th>
            </tr>
          </thead>
          <tbody>
            {
              courses
              && courses.map((course) => (
                <tr key={course.id}>
                  <td data-title="Course Title">{course.display_name}</td>
                  <td data-title="Assigned">{course.enrolled}</td>
                  <td data-title="In Progress">{course.in_progress}</td>
                  <td data-title="Completed">{course.completed}</td>
                  <td data-title="Completion Rate">{course.completion_rate} %</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </TableBlock>
      {
        courses.length === 0 && (
          <div className="no-data">No results to show</div>
        )
      }
      { courses
        && Object.keys(courses).length > 0
        && (
          <PaginationBlock
            activePage={filters.page}
            itemsCountPerPage={filters.page_size}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            showPageCounter
          />
        )}
      <AnalyticsDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        drawerFilters={drawerCourseFilters}
        setDrawerFilters={setDrawerCourseFilters}
        handleApplyFilters={handleApplyFilters}
        progressFilters={courseProgressFilters}
        note="courseNote"
      />
    </StyledCourseStats>
  );
};

function mapStateToProps(state) {
  const { results, count, filters } = state.COURSESTATDATA;
  const courseProgressFilters = filters.progress_filters;
  return {
    count,
    filters,
    courseProgressFilters,
    courses: results,
  };
}

export default connect(mapStateToProps)(CourseStats);
