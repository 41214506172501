import { LMS_BASE_URL } from 'config';

const axios = require('axios');

const fetchStats = () => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/analytics/stats/`,
  };

  return axios(requestOtions);
};

const fetchLearners = (params) => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/analytics/learners/`,
    params,
  };

  return axios(requestOtions);
};

const fetchCoursesStats = (params) => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/courses/stats/`,
    params,
  };

  return axios(requestOtions);
};

const downloadStatsCSV = (params) => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/analytics/download`,
    responseType: 'blob',
    params,
  };

  return axios(requestOtions);
};

/* eslint-disable import/prefer-default-export */
export const analyticsService = {
  fetchStats,
  fetchLearners,
  fetchCoursesStats,
  downloadStatsCSV,
};
