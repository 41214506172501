import analyticsConstants from 'analytics/constants';
import { INITIAL_STATE_FETCH_LEARNERS } from 'utils/values';

const fetchLearners = (state = INITIAL_STATE_FETCH_LEARNERS, action) => {
  switch (action.type) {
    case analyticsConstants.FETCH_LEARNERS: {
      const { filters, data } = action;
      const {
        next,
        count,
        results,
        previous,
      } = data;

      return {
        ...state,
        next,
        count,
        results,
        previous,
        filters,
      };
    }
    case analyticsConstants.LEARNER_PROGRESS_FILTERS: {
      const { filters } = state;
      filters.progress_filters = { ...action.progressFilters };
      return {
        ...state,
        filters: { ...filters },
      };
    }
    default:
      return state;
  }
};

const fetchCourseStats = (state = INITIAL_STATE_FETCH_LEARNERS, action) => {
  switch (action.type) {
    case analyticsConstants.FETCH_COURSES_STATS_SUCCESS: {
      const { filters, data } = action;
      const {
        next,
        count,
        results,
        previous,
      } = data;

      return {
        ...state,
        next,
        count,
        results,
        previous,
        filters,
      };
    }
    default:
      return state;
  }
};

/* eslint-disable import/prefer-default-export */
export const analyticReducer = {
  fetchLearners,
  fetchCourseStats,
};
